<template>
  <Vidle :loop="true" :reminders="[30]" :wait="1" :duration="timeout" @idle="onIdle" @remind="onRemind" />
</template>

<script lang="ts" setup>
import Vidle from 'v-idle';

const { $toastSuccess, $toastInfo } = useNuxtApp();
const { data: $auth } = useAuth();
const { signOut } = useProfileStore();

const { timeout } = defineProps({
  timeout: {
    type: Number,
    default: 300,
  },
});

const onIdle = async () => {
  $toastSuccess(`🔒 Automatically logged out after ${timeout / 60}m of inactivity`);
  localStorage.setItem('snap_is_agent', `${$auth.value?.accountAppType === 2}`);
  await signOut();
};

const onRemind = () => {
  $toastInfo(`🔒 You will be automatically logged out after 30s of inactivity`);
};
</script>
